.SummaryPage {
    .page-header {
        padding: 5px 15px;
        h2 {
            margin-bottom: 0;
            font-size: 24px;
        }
    }
    .comfort-zone-btn {
        font-size: 18px;
    }
    .info-btn {
        cursor: pointer;
        color: #ee6e1f;
    }
    .target-temp-change {
    	font-size: 16px;
    	font-weight: bold;
    	&.positive {
            color: #c00;
    	}
    	&.negative {
            color: #33c;
    	}
    }
    .user-distribution {
        .cold {
            color: #33c;
            &:after {
                content: ' / ';
                color: #000;
            }
        }
        .normal {
            color: #0a0;
            &:after {
                content: ' / ';
                color: #000;
            }
        }
        .hot {
            color: #c00;
        }
    }
    .comfort-zone-btn {
        cursor: pointer;
        transition: color .3s;
        &:hover {
            color: #ee6e1f;
        }
    }
	.zone-request-submessage {
		font-size: 11px;
	}
}