body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background-color: #f7f7f7;
}

*::-webkit-scrollbar-thumb {
    background-color: #ee6e1f;
    outline: 1px solid #ee6e1f;
}

.ant-table-thead > tr > th {
	background: #fbe6d4;
	&.ant-table-column-sort,
	&.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
		background: #ee6e1f;
		color: #fff;
	}
	.ant-table-column-sorter-up.on,
	.ant-table-column-sorter-down.on {
		color: #fbe6d4;
	}
}

.ant-form-item {
	margin-bottom: 12px;
}