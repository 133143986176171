.TicketsPage {
    .page-header {
        padding: 5px 15px;
        h2 {
            margin-bottom: 0;
            font-size: 24px;
        }
    }
    .ticket-card-list {
        position: relative;
        padding: 10px 15px;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        .instruction {
            text-align: center;
            font-size: 18px;
        }
        .TicketCard {
            flex: 0 0 auto;
        }
    }
    .handle-btn {
        font-size: 18px;
        cursor: pointer;
        transition: color .3s;
        &:hover {
            color: #ee6e1f;
        }
    }
    .ticket-process-submessage {
        font-size: 11px;
    }
    .ant-tabs-bar {
        margin-bottom: 0;
    }
}