.DashboardPage {
    height: 100vh;
    .header {
        background: #fbe6d4;
        font-size: 16px;
        .logo {
            width: 120px;
            float: left;
            margin: 8px 32px 8px 0px;
        }
        .left-section {
            float: left;
            .ant-select {
                width: 180px;
            }
        }
        .right-section {
            float: right;
            .user-section {
                display: inline-block;
            }
            .icon-btn-gp {
                display: inline-block;
                .icon-btn {
                    display: inline-block;
                    margin: 0px 12px;
                    font-size: 20px;
                    cursor: pointer;
                    transition: color .3s;
                    &:hover {
                        color: #ee6e1f;
                    }
                    &:first-of-type {
                        margin-left: 24px;
                    }
                    &:last-of-type {
                        margin-right: 0;
                    }
                    &.disabled {
                        color: rgba(0, 0, 0, 0.25);
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
    .Sider {
        background: #fbe6d4;
        .ant-menu-light {
            background: #fbe6d4;
            .ant-menu-item-selected {
                background-color: #ee6e1f;
                a {
                    color: #fff;
                }
            }
            .ant-menu-sub {
                background: #fbe6d4;
                box-shadow: none;
            }
        }
    }
    .ant-layout {
        background: #fff;
    }
}