.LoginPage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    .LoginPage-container {
        width: 100%;
        .LoginPage-logo-container {
            text-align: center;
            margin-bottom: 10px;
            .LoginPage-logo {
                width: 200px;
                margin: 0px auto;
            }
            p {
                font-size: 18px;
                margin-top: 10px;
                margin-bottom: 0;
            }
        }
        .LoginPage-form-container {
            max-width: 300px;
            margin: 0 auto;
            .error-message {
                height: 20px;
                color: #f5222d;
            }
            .LoginPage-form {
                .login-form-forgot {
                    float: right;
                }
                .login-form-button {
                    width: 100%;
                    font-size: 16px;
                }
            }
        }
    }
}